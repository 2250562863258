import React, { PropsWithChildren, ReactElement } from "react";
import styles from "./managers.module.scss";

interface ManagersProps {
    dataRaw: any;
}

const Managers = (props: PropsWithChildren<ManagersProps>): ReactElement => {
    const { dataRaw } = props;
    return (
        <div className={styles.wrapper}>
            <div className={styles.imgWrapper}>
            <img
                src={dataRaw.profile_picture.url}
                alt={dataRaw.profile_picture.alt}
            />
            </div>

            <h4>{dataRaw.name[0].text}</h4>
            <p>{dataRaw.position[0].text}</p>
        </div>
    );
};

export default Managers;
