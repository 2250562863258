import React, { PropsWithChildren, ReactElement } from "react";
import styles from "./amenities.module.scss";

interface AmenitiesProps {
    dataRaw: any;
}

const Amenities = (props: PropsWithChildren<AmenitiesProps>): ReactElement => {
    const { dataRaw } = props;

    return (
        <div className={styles.wrapper}>
            <button className={styles.card}>
                <img
                    className={styles.imageContainer}
                    src={dataRaw.image.url}
                    alt={dataRaw.image.alt}
                />
                <div className={styles.titleAndInfoWrapper}>
                    <h4 className={styles.cardTitle}>{dataRaw.title[0].text}</h4>

                    {dataRaw.description && dataRaw.description[0]?.text && <div className={styles.information}>
                        <p className={styles.informationDesc}>
                            {dataRaw.description[0].text}
                        </p>
                    </div>}

                    <span className={styles.moreInfoBtn}>See more</span>
                </div>
            </button>
        </div>
    );
};

export default Amenities;
